import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from 'src/app/global/services/local-storage.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CheckTokenError } from 'src/app/auth/interfaces/auth.interface';

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private localStoregeService: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  private onDestroyStorage() {
    this.localStoregeService.onDestroyLoggedUser();
    this.router.navigate(['/auth']);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(req.url.includes('/api/login') || req.url.includes('/api/check-token')){
      return next.handle(req);
    }

    const tokenData = this.localStoregeService.onGetLoggedUser();
    const { token, token_due_date } = tokenData;
    const dateTokenDueDate = new Date(token_due_date!);
    const dateNow = new Date();

    if(token && dateTokenDueDate > dateNow){
      this.authService.checkToken(token).subscribe({
        next: (res) => {
          this.localStoregeService.onRefreshToken(res.token_vigency)
        },
        error: (er) => {
          console.log(er)
          this.onDestroyStorage();
        }
      })

      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      return next.handle(req);
    } else {
      this.onDestroyStorage();
      return throwError('Token expired');
    }
  }
}
