import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthLoginInterface, AuthLoginSuccess, CheckTokenSuccess, UserObj,  } from '../interfaces/auth.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor( 
    private http: HttpClient,
  ) { }

  private _fetchUrl = environment.fetchUrl;
  private _fetchHeaders = {
    'Content-Type':'application/json',
    Accept: 'application/json'
  }

  public login(loginObj: AuthLoginInterface): Observable<AuthLoginSuccess>{
    return this.http.post<AuthLoginSuccess>(`${this._fetchUrl}/login`, loginObj, { headers: this._fetchHeaders });
  }

  public checkToken(token: string): Observable<CheckTokenSuccess>{
    
    const headerObj = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }

    return this.http.get<CheckTokenSuccess>(`${this._fetchUrl}/check-token`, { headers: headerObj });
  }

  public logout(token: string): Observable<any>{
    const headerObj = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }

    return this.http.get(`${this._fetchUrl}/logout`, { headers: headerObj });
  }
}
