import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatch, Route, Router, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { catchError, map, Observable, of } from "rxjs";
import { LocalStorageService } from "src/app/global/services/local-storage.service";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanMatch, CanActivate {
    constructor (
        private localStorage:LocalStorageService,
        private router: Router
    ) { }

    private checkAuthStatus(): Observable<boolean> {
        return this.localStorage.onCheckTokenItem().pipe(
          map(authenticated => {
            if (!authenticated) {
              const queryParams = {
                unauthenticated: 1
              };
              this.router.navigate(['./auth'], { queryParams });
            }
            return authenticated;
          }),
          catchError(error => {
            // console.error('Error en la verificación de autenticación:', error);
            return of(false);
          })
        );
      }
    
      canMatch(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> {
          return this.checkAuthStatus();
      }
      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
          return this.checkAuthStatus();
      }
}