import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserObj } from 'src/app/auth/interfaces/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public onCheckTokenItem(): Observable<boolean> {
    if(localStorage.getItem('token')){
      return of(true);
    } else {
      return of(false)
    }
  }

  public onSetLoggedUser(userObj: UserObj){
    localStorage.setItem('client_id', userObj.client_id.toString());
    localStorage.setItem('email', userObj.email);
    localStorage.setItem('id', userObj.id.toString());
    localStorage.setItem('name', userObj.name);
    localStorage.setItem('phone', userObj.phone);
    localStorage.setItem('rol_id', userObj.rol_id.toString());
    localStorage.setItem('token', userObj.token);
    localStorage.setItem('token_due_date', userObj.token_due_date.toString());
  }

  public onGetLoggedUser(){
    const loggedUser: UserObj = {
      client_id: parseInt(localStorage.getItem('client_id')!),
      email: localStorage.getItem('email')!,
      id: parseInt(localStorage.getItem('id')!),
      name: localStorage.getItem('name')!,
      phone: localStorage.getItem('phone')!,
      rol_id: parseInt(localStorage.getItem('rol_id')!),
      token: localStorage.getItem('token')!,
      token_due_date: new Date(localStorage.getItem('token_due_date')!)
    }

    return loggedUser;
  }

  public onDestroyLoggedUser(){
    localStorage.removeItem('client_id');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('phone');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('token');
    localStorage.removeItem('token_due_date');
  }

  public onRefreshToken(token_due_date: Date){
    localStorage.setItem('token_due_date', token_due_date.toString());
  }
}
